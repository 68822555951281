import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { authReducer } from "./auth/authState";
import { appModeReducer } from "./appMode/appModeState";
import { headerReducer } from "./header/headerState";
import { selectionReducer } from "./selectionState/selectionState";

const rootReducer = combineReducers({
  auth: authReducer,
  appMode: appModeReducer,
  header: headerReducer,
  selection: selectionReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistedStore = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export default store;
