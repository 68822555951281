import { useTranslation } from "react-i18next";

const useI18n = (namespace: string) => {
  const { t, i18n } = useTranslation(namespace);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  // Add console logs to debug
  // console.log("Current Language:", i18n.language);
  // console.log("Namespace:", namespace);
  // console.log(
  //   "Resource Bundle:",
  //   i18n.getResourceBundle(i18n.language, namespace),
  // );

  // debugger;

  return { t, changeLanguage, currentLanguage: i18n.language };
};

export default useI18n;
