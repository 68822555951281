import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
};

const authState = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokensAction: (
      state,
      action: PayloadAction<{ accessToken: string; refreshToken: string }>,
    ) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    clearTokensAction: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
});

export const { setTokensAction, clearTokensAction } = authState.actions;
export const authReducer = authState.reducer;

const selectAuthState = (state: any) => state.auth;
export const selectAuth = createSelector([selectAuthState], (auth) => auth);

export const useAuth = () => {
  const dispatch = useDispatch();

  const setTokens = (accessToken: string, refreshToken: string) => {
    dispatch(setTokensAction({ accessToken, refreshToken }));
  };

  const clearTokens = () => {
    dispatch(clearTokensAction());
  };

  const tokens = useSelector(selectAuth);

  return {
    setTokens,
    clearTokens,
    tokens,
    isAuthenticated: !!tokens.accessToken,
  };
};

export default useAuth;
