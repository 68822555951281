import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

interface AppModeState {
  isEditMode: boolean;
}

const initialState: AppModeState = {
  isEditMode: false,
};

const appModeState = createSlice({
  name: "appMode",
  initialState,
  reducers: {
    toggleEditModeAction: (state) => {
      state.isEditMode = !state.isEditMode;
    },
    setEditModeAction: (state, action: PayloadAction<boolean>) => {
      state.isEditMode = action.payload;
    },
  },
});

export const { toggleEditModeAction, setEditModeAction } = appModeState.actions;
export const appModeReducer = appModeState.reducer;

const selectAppModeState = (state: any) => state.appMode;
export const selectAppMode = createSelector(
  [selectAppModeState],
  (appMode) => appMode.isEditMode,
);

export const useAppMode = () => {
  const dispatch = useDispatch();

  const toggleEditMode = () => {
    dispatch(toggleEditModeAction());
  };

  const setEditMode = (isEditMode: boolean) => {
    dispatch(setEditModeAction(isEditMode));
  };

  const isEditMode = useSelector(selectAppMode);

  return { toggleEditMode, setEditMode, isEditMode };
};

export default useAppMode;
