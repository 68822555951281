import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Image,
  Avatar,
  Button,
  Text,
  useMediaQuery,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// import { EditIcon, CheckIcon } from "@chakra-ui/icons";
import Logo from "../assets/images/EYA_logo.svg";
import useAppMode from "../store/appMode/appModeState";
// import { useGetProfile } from "../modules/accountAndSettings/hooks/useGetProfile";
import useAuth from "../store/auth/authState";
import useHeaderState from "../store/header/headerState";
// import WelcomeModal from "./dialogs/WelcomeModal";
// import EditModal from "./dialogs/EditModal";
import useI18n from "../hooks/useI18n";
import { FaListAlt, FaImages } from "react-icons/fa";

const Header: React.FC = () => {
  const [isMobileDevice] = useMediaQuery("(min-width: 768px)");
  const { isEditMode, toggleEditMode } = useAppMode();
  // const { data: profile } = useGetProfile();
  const { tokens } = useAuth();
  const {
    setShowProfileButton,
    setShowEditButton,
    showNavigationButtons,
    showProfileButton,
    showEditButton,
  } = useHeaderState();
  const location = useLocation();
  const isAuthenticated = !!tokens.accessToken;
  const ml = isMobileDevice ? "8vw" : "5vw";

  const { t, currentLanguage } = useI18n("auth");

  useEffect(() => {
    setShowProfileButton(false);
    // setShowEditButton(false);
  }, [location, tokens]);

  // if (location.pathname === "/categories" || location.pathname === "/") {
  if (isAuthenticated) {
    setShowProfileButton(true);
  }
  //   setShowEditButton(true);
  // }

  //   const hasSeenDemo = localStorage.getItem("hasSeenDemo");
  //   if (!hasSeenDemo) {
  //     localStorage.setItem("hasSeenDemo", "true");
  //   }
  // }, [location, tokens]);

  // const onEditBtnClick = () => {
  //   if (isAuthenticated) {
  //     toggleEditMode();
  //   }
  // };

  return (
    <>
      <Flex
        as="header"
        alignItems="center"
        justifyContent="space-between"
        fontFamily="Poppins Polly, sans-serif"
        px={ml}
      >
        <Box>
          <ChakraLink as={RouterLink} to="/categories">
            <Flex align="center" justifyContent="center">
              <Image boxSize="110px" src={Logo} alt="EYA Logo" />
            </Flex>
          </ChakraLink>
        </Box>

        {isAuthenticated && (
          <Flex alignItems="center" justifyContent="center">
            <ChakraLink as={RouterLink} to="/categories">
              <Button
                size="lg"
                colorScheme="teal"
                variant="solid"
                leftIcon={<FaListAlt size="25px" />}
              >
                Categories
              </Button>
            </ChakraLink>

            {/* Images Section Button with FaImages icon */}
            <ChakraLink as={RouterLink} to="/images" ml={12}>
              <Button
                size="lg"
                colorScheme="teal"
                variant="solid"
                leftIcon={<FaImages size="25px" />}
              >
                Images
              </Button>
            </ChakraLink>
          </Flex>
        )}

        {/* <Flex alignItems="center">
          {showProfileButton && (
            <ChakraLink
              as={RouterLink}
              to="/logout"
              _hover={{ textDecoration: "none" }}
            >
              <Flex alignItems="center" ml={4} cursor="pointer">
                <Avatar
                  size="md"
                  name={profile?.name || profile?.email}
                  src={profile?.avatarUrl}
                />
                <Text ml={2} fontWeight="bold" size={"lg"}>
                  {profile?.name}
                </Text>
              </Flex>
            </ChakraLink>
          )}
          {!isAuthenticated && (
            <>
              <ChakraLink as={RouterLink} to="/auth/sign-in">
                <Button
                  size="lg"
                  flexDirection="column"
                  colorScheme="teal"
                  variant="outline"
                  mr={4}
                >
                  {t("sign_in")}
                </Button>
              </ChakraLink>
            </>
          )}
        </Flex> */}
      </Flex>
    </>
  );
};

export default Header;
