import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PropsWithChildren, Suspense } from "react";
import CenterSpinner from "../sharedComponents/CenterSpinner";
import { useIsRestoring } from "@tanstack/react-query";
import useAuth from "../store/auth/authState";

const LazyComponent = (Component: React.LazyExoticComponent<any>) => {
  return (
    <Suspense fallback={<CenterSpinner />}>
      <Component />
    </Suspense>
  );
};

const GuardedRoute = ({ children }: PropsWithChildren) => {
  const { tokens } = useAuth();
  const isRestoring = useIsRestoring();
  if (isRestoring) return <CenterSpinner />;
  if (!tokens.accessToken) return <Navigate to="/auth/sign-in" replace />;
  return <>{children}</>;
};

const Router = () => {
  // Lazy load all components
  const Login = React.lazy(() => import("./auth/components/Login.page"));
  const Main = React.lazy(() => import("./main/components/Main.page"));
  const CategoriesPage = React.lazy(
    () => import("./categories/components/CategoriesGrid.page"),
  );
  const ImageSection = React.lazy(
    () => import("./images/components/ImageSection"),
  );
  const LogoutComponent = React.lazy(
    () => import("./accountAndSettings/components/logoutComponent"),
  );

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/auth/sign-in" element={LazyComponent(Login)} />

      {/* Protected Routes */}
      <Route
        path="/categories"
        element={<GuardedRoute>{LazyComponent(CategoriesPage)}</GuardedRoute>}
      />

      <Route
        path="/images"
        element={<GuardedRoute>{LazyComponent(ImageSection)}</GuardedRoute>}
      />

      <Route
        path="/logout"
        element={<GuardedRoute>{LazyComponent(LogoutComponent)}</GuardedRoute>}
      />

      {/* Default Route */}
      <Route
        path="/"
        element={<GuardedRoute>{LazyComponent(ImageSection)}</GuardedRoute>}
      />

      {/* Catch all - Redirect to main page */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Router;
