import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

interface SelectionState {
  selectedParentId?: string;
  selectedCategoryId?: string;
}

const initialState: SelectionState = {
  selectedParentId: undefined,
  selectedCategoryId: undefined,
};

const selectionState = createSlice({
  name: "selection",
  initialState,
  reducers: {
    setSelectedParentIdAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.selectedParentId = action.payload;
    },
    setSelectedCategoryIdAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.selectedCategoryId = action.payload;
    },
    clearSelectionAction: (state) => {
      state.selectedParentId = undefined;
      state.selectedCategoryId = undefined;
    },
  },
});

export const {
  setSelectedParentIdAction,
  setSelectedCategoryIdAction,
  clearSelectionAction,
} = selectionState.actions;

export const selectionReducer = selectionState.reducer;

// Selectors
const selectSelectionState = (state: any) => state.selection;

export const selectSelectedParentId = createSelector(
  [selectSelectionState],
  (selection) => selection.selectedParentId,
);

export const selectSelectedCategoryId = createSelector(
  [selectSelectionState],
  (selection) => selection.selectedCategoryId,
);

// Hook for easy access to selection state and actions
export const useSelection = () => {
  const dispatch = useDispatch();

  const setSelectedParentId = (parentId: string | undefined) => {
    dispatch(setSelectedParentIdAction(parentId));
  };

  const setSelectedCategoryId = (categoryId: string | undefined) => {
    dispatch(setSelectedCategoryIdAction(categoryId));
  };

  const clearSelection = () => {
    dispatch(clearSelectionAction());
  };

  const selectedParentId = useSelector(selectSelectedParentId);
  const selectedCategoryId = useSelector(selectSelectedCategoryId);

  return {
    selectedParentId,
    selectedCategoryId,
    setSelectedParentId,
    setSelectedCategoryId,
    clearSelection,
  };
};

export default useSelection;
